import React, { useEffect } from 'react'
import Button from './components/Button'
import Table from './components/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import reportStore from './stores/report'
import Modal from './components/Modal'
import { Text } from './components/Fields'
import { DateTime } from 'luxon'
import numeral from 'numeral'

export default function () {
  const [
    fetchReports,
    reports,
    report,
    setReport,
  ] = reportStore((state) => [
    state.fetch,
    state.collection,
    state.report,
    state.setReport,
  ])

  useEffect(() => {
    fetchReports()
  }, [])

  function newReport() {
    setReport({})
  }

  async function save() {
    const res = await fetch('/api/reports.json', {
      method: 'POST',
      body: JSON.stringify({report: report}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await res.json()
    setReport(false)
    fetchReports()
  }

  return (
    <div>
      <div className="flex justify-between gap-4 mb-4 items-end">
        <h1 className="text-4xl">Reports</h1>
        <Button onClick={newReport} type="success">
          New Report
        </Button>
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Th>ID</Table.Th>
            <Table.Th>Start Date</Table.Th>
            <Table.Th>End Date</Table.Th>
            <Table.Th>Total Products</Table.Th>
            <Table.Th>Total Price</Table.Th>
            <Table.Th>Total Cost</Table.Th>
            <Table.Th width="135"></Table.Th>
            <Table.Th width="135"></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {reports && reports.map(report => (
            <Row key={report.id} report={report} />
          ))}
        </Table.Body>
      </Table>
      <Modal isOpen={report}>
        <Modal.Header onClose={() => setReport(false)}>
          New Report
        </Modal.Header>
        <Modal.Body>
          <Text
            label="Start Date"
            onChange={e => setReport({...report, start_date: e.target.value})} groupClass="mb-4"
            value={report.start_date}
            type="date"
          />
          <Text
            label="End Date"
            onChange={e => setReport({...report, end_date: e.target.value})} groupClass="mb-4"
            value={report.end_date}
            type="date"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-between items-center">
            <a onClick={() => setReport(false)} className="link">
              Cancel
            </a>
            <Button onClick={save} type="success">
              Generate Report
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

function Row({ report }) {
  const [removing, setRemoving] = React.useState(false)
  const [deleteReport] = reportStore((state) => [state.deleteReport])

  async function remove(e) {
    e.preventDefault()
    await deleteReport(report)
    setRemoving(false)
  }

  return (
    <Table.Row>
      <Table.Td>{report.id}</Table.Td>
      <Table.Td>{DateTime.fromISO(report.start_date).toLocaleString()}</Table.Td>
      <Table.Td>{DateTime.fromISO(report.end_date).toLocaleString()}</Table.Td>
      <Table.Td className="text-right">{numeral(report.total_products).format('0,0')}</Table.Td>
      <Table.Td className="text-right">{numeral(report.total_price).format('$0,0.00')}</Table.Td>
      <Table.Td className="text-right">{numeral(report.total_cost).format('$0,0.00')}</Table.Td>
      <Table.Td>
        <Button type="danger" onClick={() => setRemoving(true)}>
          <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
          Remove
        </Button>
        <Modal isOpen={removing} onClose={() => setRemoving(false)}>
          <Modal.Header onClose={() => setRemoving(false)}>
            Are you sure?
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to remove report #{report.id}? This action cannot be undone.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-between">
              <a href="#" onClick={() => setRemoving(false)}>
                Cancel
              </a>
              <Button type="danger" onClick={remove}>
                <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
                Remove
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Table.Td>
      <Table.Td>
        <Button to={`/reports/${report.id}`}>
          <FontAwesomeIcon icon={icon({name: 'circle-info'})} className="mr-2" />
          Details
        </Button>
      </Table.Td>
    </Table.Row>
  )
}
